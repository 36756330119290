import React from 'react'
import Hero from '../../components/Hero/Hero'
import Greet from '../../components/Greet/Greet'
import Categories from '../Categories/Categories'
import Collections from '../Collections/Collections'
import Media from '../Media/Media'
import './Home.scss'

const Home = () => {
    return (
        <div>
            <Hero />
            <Greet />
            <Categories />
            <Collections />
            <Media />
            <iframe className="slideShow" title="slideshow" src={process.env.REACT_APP_HOME_SLIDESHOW}></iframe>
        </div>
    )
}

export default Home
