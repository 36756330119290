import React, {useState, useEffect} from 'react'
import "./PriestMemo.scss"
import Title from '../../components/Title/Title'
import { useParams } from 'react-router-dom'
import { getPriestById } from '../../services/PersonService'


const PriestMemo = () => {
    const params = useParams()

    const [priestData, setPriestData] = useState({"name":null, "lived":null})
    const [content, setContent] = useState(<div className="page-message">Adatok betöltése...</div>)
    

    useEffect(() => {

        getPriestById(params.id).then(
            data => {
                setPriestData(
                    {"name": data.name, "lived":data.lived}
                    )
                setContent(data.content)
            }
        )

    }, [params.id])

    return (
        <div id="priestMemoPage" className="content-holder">
        <Title title_text = {priestData.name}/>
            <div className="container">
                <h2 className="lived">({priestData.lived})</h2>
                <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </div>
    )
}

export default PriestMemo
