import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Title from '../../components/Title/Title'
import {getCollectionCategories, getCollectionSubcategoriesByCategory} from '../../services/CollectionsService'

import './Collection.scss'

const Collection = () => {
    const params = useParams()
    const [mainTitle, setMainTitle] = useState(params.shortName);

    useEffect(() => {
        getCollectionCategories().then(
            data => {
                let res = data.find(data => data.short_name === params.shortName).title;
                setMainTitle(res)
            });
    },[mainTitle, params]);

    const [apiResponse, setApiResponse] = useState(<div className="page-message">Adatok betöltése...</div>);

    useEffect(() => {
        getCollectionSubcategoriesByCategory(params.shortName).then(
            data => {
  
                let components = [];
                if(data.length > 0){
                    for(var i = 0;i<data.length;i++) {
                        components.push(
                        <div key={i} className=" col-md-6">
                            {
                                <CollectionSection 
                                title={data[i].title} 
                                image={data[i].image}
                                target={params.shortName + "/" + data[i].id}
                                />
                            }
                        </div>);
                    }
                }else{
                    components.push(<div className="page-message">Feltöltés alatt,<br/>látogasson vissza később!</div>)
                }
                
                setApiResponse(components)});
    },[params.shortName]);

    return (
        <div  className="content-holder">
        <Title title_text={mainTitle}/>
        <div className="container">
            <br/>
            <div className="row">
                {apiResponse}
            </div>
        </div>
    </div>
    )
}

export default Collection

const CollectionSection = (props) => {
    let targetUrl = props.target
    return (
        <div className="collection-section-holder">
            <div className="category-section">
                {props.image ?
                <div className="img-holder">
                    <a href={targetUrl}>
                    <img src={props.image} alt={props.title}/>
                    </a>
                </div>
                :
                <div/>
                }
                <div className="description-holder">
                    <a href={targetUrl}><h2>{props.title}</h2></a>
                    <a className="more-link" href={targetUrl}>Bővebben</a>
                </div>
            </div>
        </div>
    )
}