import React, { useState, useEffect } from "react";
import "./Priests.scss";
import Title from "../../components/Title/Title";
import { getPriestsShort } from "../../services/PersonService";



const Priests = () => {
  const [apiResponse, setApiResponse] = useState(
    <div className="page-message">Adatok betöltése...</div>
  );

  useEffect(() => {
    getPriestsShort().then((data) => {
      let components = [];
      let lastFirstLetter = "";
      let priestLinks = [];

      for (var i = 0; i < data.length; i++) {
        let firstLetter = letterGroupConverter(data[i].name[0])

        if(firstLetter !== lastFirstLetter) {
          if(lastFirstLetter !== ""){
            components.push(letterSectionBuilder(lastFirstLetter, priestLinks, lastFirstLetter))
            priestLinks = []
          }
          lastFirstLetter = firstLetter
        }
        priestLinks.push(
          priestLinkBuilder(data[i].name, data[i].lived, data[i].id)
        );
      }

      setApiResponse(components);
    });
  }, []);

  return (
    <div id="priests" className="content-holder">
      <Title title_text="Görögkatolikus papok történeti névtára" />
      <br />
      <br />
      <div className="container">
        <div className="row introduction">
          <p>
            Adatbázisunk azon <i>elhunyt</i> görögkatolikus papok és szerzetesek
            adatait tartalmazza, akik a Görögkatolikus Metropólia
            egyházmegyéiben (
            <i>
              Hajdúdorogi Főegyházmegye, Miskolci Egyházmegye, Nyíregyházi
              Egyházmegye
            </i>
            ), illetve jogelőd egyházkormányzati egységeiben (
            <i>Hajdúdorogi Egyházmegye és Miskolci Apostoli Exarchátus</i>)
            szolgáltak.{" "}
          </p>
          <p>
            A listát kiegészítjük azon papok adataival, akik nem szolgáltak a
            magyar görögkatolikus egyházmegyékben (pl. 1912 előtt elhunytak,
            vagy 1920 után az utódállamokban kezdték meg papi szolgálatukat), de
            a családi hagyomány és emlékezet szerint a magyar görögkatolikusok
            közösségéhez tartozónak vallották magukat. Az ő nevük előtt egy
            csillag (*) szerepel.
          </p>
          <p>
            A még szolgálatban lévő vagy nyugdíjas papok adatai az egyházmegyék
            honlapjain megtalálhatók:
          </p>
          <p>
            <a href="https://hd.gorogkatolikus.hu/adattar-papjaink">
              Hajdúdorogi Főegyházmegye
            </a>
            <br />
            <a href="https://www.migorkat.hu/szolgalattevok">
              Miskolci Egyházmegye
            </a>
            <br />
            <a href="https://www.nyirgorkat.hu/?q=papok&l=hu">
              Nyíregyházi Egyházmegye
            </a>
          </p>

          <p>
            Az egyes oldalakon található szöveg és képanyag pontosítható,
            javítható és bővíthető. Javaslatokat a{" "}
            <a href="mailto:papcsaladok@gmail.com">papcsaladok@gmail.com</a>{" "}
            címen várunk.{" "}
          </p>
          <p>
            2021-ben megjelent{" "}
            <b>
              Ivancsó István: A magyar görögkatolikus egyház papjainak
              nekrológjai (1912-2020)
            </b>{" "}
            című kötete, melynek adatai kiegészíthetik a papi emlékoldalak
            adatait.
          </p>
          <p>
            A kötet{" "}
            <a href="https://byzantinohungarica.com/index.php/ivancso-istvan-a-magyar-gorogkatolikus-egyhaz-papjainak-nekrologjai-1912-2020">
              ide kattintva
            </a>{" "}
            letölthető.{" "}
          </p>
          <p>
            Az emlékoldalakon elhelyezett fényképek az egyes családok tulajdonát
            képezik. Ismételt felhasználásuk engedélyhez kötött.
            Kapcsolatfelvétel:{" "}
            <a href="mailto:papcsaladok@gmail.com">papcsaladok@gmail.com</a>
          </p>
        </div>

        <div className="row resultHolder">
          {apiResponse}
        </div>
      </div>
    </div>
  );
};


function letterGroupConverter(letter){
  letter = letter.toUpperCase()

  if(letter === 'A' || letter === 'Á'){ return 'A-Á'}
  else if(letter === 'E' || letter === 'É'){ return 'E-É'}
  else if(letter === 'I' || letter === 'Í'){ return 'I-Í'}
  else if(letter === 'O' || letter === 'Ó'){ return 'O-Ó'}
  else if(letter === 'U' || letter === 'Ú'){ return 'U-Ú'}
  else{ return letter }
}

function letterSectionBuilder(letter, links, key) {
  return (
    <div key={key}>
      <a
        className="btn letterBtn"
        data-bs-toggle="collapse"
        href={"#collapse_item" + key}
        role="button"
        aria-expanded="false"
        aria-controls={"collapse_item" + key}
      >
        {letter}
      </a>
      <div className="collapse" id={"collapse_item" + key}>
        <div className="card card-body">
          <div className="row">
            {links}
          </div>
        </div>
      </div>
    </div>
  );
}

function priestLinkBuilder(name, lived, id){
  return (
    <div key={id} className="priestLinkHolder">
      <a href={"/generations/priest/"+id}>
        {name} ({lived})
      </a>
    </div>
  )
}

export default Priests;
