import React from "react";
import "./Footer.scss";
import footer_logo from "../../img/footer/footer-logo.webp";
import data from "./contact.json";

import phone_icon from "../../img/footer/phone-solid.svg";
import email_icon from "../../img/footer/envelope-solid.svg";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-sm-4">
            <img className="footer-logo" src={footer_logo} alt="" />
          </div>
          <div className="col-sm-8">
            
            <ul>
                <li>
                    <a href={"tel:" + data.phone}><img src={phone_icon} alt="phone-icon" /> {data.phone}</a>
                </li>
                <li>
                    <a href={"mailto: " + data.email}><img src={email_icon} alt="email-icon" /> {data.email}</a>
                </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <span className="triangle"></span>
        <p>
          © Byzantinohungarica - Görögkatolikus Örökségismereti Honlap - 2022
        </p>
      </div>
    </footer>
  );
};

export default Footer;
