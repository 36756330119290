import React, {useState, useEffect} from 'react'
import CategorySection from './CategorySection'
import {getSubCategories} from '../../services/CategoryService'
import { useParams } from 'react-router-dom'
import Title from '../../components/Title/Title'
import subcatalias from './subcatalias.json'

const SubSubCategoryPage = (props) => {
    const subcatprefix = useParams().subcatprefix
    
    let mainTitle = subcatprefix.split('-')[1]
    
    subcatalias.aliases.forEach((value)=>{
        if(value.shortname === subcatprefix.split('-')[1]){
            mainTitle = value.alias
        }
    })

    const [subcategories, setSubcategories] = useState([])

    useEffect(() => {
        getSubCategories().then(
            data => {
                let subcategoriesList = []
                for(var i in data){
                    if(data[i].short_name.includes(subcatprefix)){
                        console.log(data[i].short_name)

                        subcategoriesList.push(
                            <CategorySection key={i} data={data[i]}/>
                        )
                    }
                }
                setSubcategories(subcategoriesList)
            });
    },[subcatprefix]);

    return (
        <div  className="content-holder">
            <Title title_text={mainTitle}/>
            <div className="container">
                <br/>
                <div className="row">
                { subcategories }
                </div>
            </div>
        </div>
    )
}

export default SubSubCategoryPage
