import React, {useState, useEffect} from "react";
import "./Greet.scss";

const Greet = () => {
  const[content, setContent] = useState("Betöltés...")

  useEffect(()=>{
    fetch(process.env.REACT_APP_API_URLS_PREFIX + "greet.json")
    .then(res => res.json())
    .then(data => {
      setContent(data.data)
      console.log(data.data)
    })
  }, [])

  return (
    <div id="greet">
       <div
      dangerouslySetInnerHTML={{__html: content}}
    />
    </div>
  );
};

export default Greet;
