import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import './Navbar.scss'
import './Hamburger.scss'


const Navbar = () => {
    let [mobile, setDeviceChange] = useState( window.innerWidth < 768 )
    const changeDevice = () =>{
        if(window.innerWidth < 768){
            setDeviceChange(true);
        }
        else{
            setDeviceChange(false);
        }
     };
     window.addEventListener('resize', changeDevice);
    return (     
        <div>
        { mobile ?
        <MobileNavbar />
        :<DesktopNavbar />
        }
        </div>          
    )
}

const DesktopNavbar = () => {
    return (
        <div id="my-navbar">
            <div>
                <ul >
                    <li><Link to="/">Főoldal</Link></li>
                    <li><Link to="/categories">Kategóriák</Link></li>
                    <li><Link to="/collections">Gyűjtemények</Link></li>
                    <li><Link to="/media">Médiatár</Link></li>
                    <li><Link to="/generations">Nemzedékről nemzedékre</Link></li>
                </ul>
            </div>
        </div>
    )
}

const MobileNavbar = () => {
    let [openMobileNavbar, switchMobileNavbar] = useState(false)
    return (
        <div id="my-mobile-navbar">
            <HamburgerIcon xhamburger={openMobileNavbar} onChange={switchMobileNavbar}/>
            <div className={"mobile-navbar " + (openMobileNavbar ? "open" : "close")}>
                <ul >
                    <li><Link onClick={() => {switchMobileNavbar(false)}} to="/">
                        Főoldal
                        </Link></li>
                    <li><Link onClick={() => {switchMobileNavbar(false)}} to="/categories">Kategóriák</Link></li>
                    <li><Link onClick={() => {switchMobileNavbar(false)}} to="/collections">Gyűjtemények</Link></li>
                    <li><Link onClick={() => {switchMobileNavbar(false)}} to="/media">Médiatár</Link></li>
                    <li><Link onClick={() => {switchMobileNavbar(false)}} to="/generations">Nemzedékről nemzedékre</Link></li>
                </ul>
            </div>
        </div>
    )
}



function HamburgerIcon(props){
    return (
        <div onClick={() => {
            props.onChange(!props.xhamburger)
        }} className={props.xhamburger ? 'hamburger x-hamburger' : 'hamburger'}>
            <div></div>
        </div>
    )
}

export default Navbar