import React, {useState, useEffect} from 'react'
import Title from '../../components/Title/Title'
import './Lifeways.scss'
import {getLifeways} from '../../services/PersonService'

const Lifeways = () => {
    const [apiResponse, setApiResponse] = useState(<div className="page-message">Adatok betöltése...</div>); 

    useEffect(() => {
        getLifeways().then(
            data => {
                let components = [];
                let last_letter = ""
                for(var i = 0;i<data.length;i++) {

                    if(data[i].name[0] !== last_letter){
                        last_letter = data[i].name[0]

                        components.push(
                        <h2 className="first_letter">{last_letter}</h2>
                        )
                    }


                    components.push(
                    <div key={i}>
                        <a 
                                className="btn nameBtn" 
                                data-bs-toggle="collapse" 
                                href={"#collapse_item"+i} 
                                role="button" 
                                aria-expanded="false" 
                                aria-controls={"collapse_item"+i}>
                                {data[i].name}
                        </a>
                        <div 
                            className="collapse" 
                            id={"collapse_item"+i}>
                            <div className="card card-body">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <h2 className="lifeway-data">{data[i].name}</h2>
                                        <h3 className="lifeway-data">{data[i].position}</h3>
                                        <h3 className="lifeway-data">{data[i].birth} | {data[i].death}</h3>

                                        {
                                            data[i].image ? <div><hr/><img className="lifeway-data" src={data[i].image} alt={data[i].name} title={data[i].name}/></div> : ""
                                        }

                                    </div>
                                    <div className="col-lg-8">
                                        <div className="content" dangerouslySetInnerHTML={{ __html: data[i].content }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                );

    }
                
                setApiResponse(components)});
    },[]);

    return (
        <div id="lifeway" className="content-holder">
            <Title title_text = "Görögkatolikus életutak" />
             <div className="container">
                <div className="row" id="accordion">
                    {apiResponse}
                </div>
             </div>
        </div>
    )
}

export default Lifeways
