const axios = require('axios');

export async function getAllCategories() {

    try{
        const response = await axios.get(process.env.REACT_APP_API_URLS_PREFIX + 'api/categories/');
        return response.data;
    }catch(error) {
        return [];
    }
}

export async function getSubCategoriesByName(short_name) {
    try{
        const response = await axios.get(process.env.REACT_APP_API_URLS_PREFIX + 'api/subcategories/' + short_name);
        return response.data;
    }catch(error) {
        return [];
    }   
}

export async function getSubCategories() {
    try{
        const response = await axios.get(process.env.REACT_APP_API_URLS_PREFIX + 'api/subcategories/');
        return response.data;
    }catch(error) {
        return [];
    }   
}

export async function getSubCategoryById(id) {
    try{
        const response = await axios.get(process.env.REACT_APP_API_URLS_PREFIX + 'api/subcategory/' + id);
        return response.data;
    }catch(error) {
        console.log(error)
        return [];
    }   
}

export async function getPublicationsBySubcategoryId(subcategory_id ) {
    try{
        const response = await axios.get(process.env.REACT_APP_API_URLS_PREFIX + 'api/publications/subcategory/' + subcategory_id);
        return response.data;
    }catch(error) {
        return [];
    }   
}

export async function getPublicationById(id) {
    try{
        const response = await axios.get(process.env.REACT_APP_API_URLS_PREFIX + 'api/publication/' + id);
        return response.data;
    }catch(error) {
        return [];
    }   
}
