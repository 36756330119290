const axios = require('axios');

export async function getCollectionCategories() {

    try{
        const response = await axios.get(process.env.REACT_APP_API_URLS_PREFIX + 'api/collection_categories/');
        return response.data;
    }catch(error) {
        return [];
    }   
}

export async function getCollectionSubcategoriesByCategory(category_id) {

    try{
        const response = await axios.get(process.env.REACT_APP_API_URLS_PREFIX + 'api/collection_subcategories/' + category_id);
        return response.data;
    }catch(error) {
        return [];
    }   
}

export async function getSubcategoryDataById(id){
    try {
        const response = await axios.get(process.env.REACT_APP_API_URLS_PREFIX + 'api/collection_subcategory/' + id)
        return response;
    } catch (error) {
        return [];
    }
}

export async function getCollectionItemsBySubcategory(subcategory_id) {

    try{
        const response = await axios.get(process.env.REACT_APP_API_URLS_PREFIX + 'api/collection_items/subcategory/' + subcategory_id);
        return response.data;
    }catch(error) {
        return [];
    }   
}

export async function getCollectionItemById(id) {

    try{
        const response = await axios.get(process.env.REACT_APP_API_URLS_PREFIX + 'api/collection_item/' + id);
        return response.data;
    }catch(error) {
        return [];
    }   
}
