import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Title from '../../components/Title/Title'
import './MediaType.scss'
import {getMediaItemsByMediaType} from '../../services/MediaService'

const alias = [
    {"typeName": "image", "title":"Képgaléria"},
    {"typeName": "youtube", "title":"Filmtár"},
    {"typeName": "sound", "title":"Hangtár"},
    {"typeName": "prezi", "title":"Prezentációk"},
]

const MediaType = () => {
    const params = useParams()
    const [myApiData, setData] = useState([])

    useEffect(() => {
        getMediaItemsByMediaType(params.mediaType).then(
            data => {
                setData(data)
            }
        );
    },[params.mediaType]);

    return (
        <div className="container-fluid content-holder">
            <div className="container">
            <Title title_text={alias.find(data => data.typeName === params.mediaType).title}/>
            {(params.mediaType === "image") ? <ImageGallery apiData={myApiData} /> : <br/>}
            {(params.mediaType === "youtube") ? <FilmGallery apiData={myApiData} /> : <br/>}
            {(params.mediaType === "sound") ? <SoundGallery apiData={myApiData} /> : <br/>}
            {(params.mediaType === "prezi") ? <PreziGallery apiData={myApiData} /> : <br/>}
            </div>
        </div>
    )
}

const ImageGallery = (props) => {
    let image_links = []
    if(props.apiData !== undefined) {
        for(let i = 0; i < props.apiData.length; i++){
            image_links.push(
            <div className="col-sm-4 col-md-3 image-link-holder" key={i}>
                <div>
                    <a href={props.apiData[i].source}>{props.apiData[i].title}
                    </a>
                </div>
            </div>
            )
        }
    }
    return(
        <div id="media-image">
            <div className="row description">
                <h3>A Hajdúdorogi Egyházmegye egykori székelyföldi vikariátusának parókiái napjainkban</h3>
                <p>A Hajdúdorogi Egyházmegyét megalapító Christifideles graeci bulla felsorol 35 székelyföldi görögkatolikus egyházközséget is, melyek - az 1910-es népszámlálási adatok alapján - a Gyulafehérvár-Fogarasi Főegyházmegyétől kerültek átsorolásra az új egyházmegyébe. Ezek a parókiák alkották a Hajdúdorogi Egyházmegyén belül a Székelyföldi Külhelynökséget, melynek központja Marosvásárhely lett. A külhelynökség mindössze 7 évig működött, a trianoni békediktátum után a parókiák visszatértek a Gyulafehérvár-Fogarasi Főegyházmegyébe. A kommunista hatalomátvételt és a görögkatolikus egyház felszámolását követően ezek az egyházközségek elenyésztek.</p>
                <p>Kutatócsoportunk tagja, Sárándi Tamás a kutatási tervnek megfelelően személyesen keresi fel ezeket a parókiákat (a koronavírus-járvány okozta korlátozások függvényében), s dokumentálja a mostani állapotukat. A fotókat folyamatosan töltjük fel.</p>
            </div>
            <div className="row">
                {image_links}
            </div>
        </div>
    )
}

const FilmGallery = (props) => {
    let video_elements = []
    if(props.apiData !== undefined) {
        for(let i = 0; i < props.apiData.length; i++){
        let video_id = props.apiData[i].source.split('/')
        video_id = video_id[video_id.length-1]
            video_elements.push(
            <div className="col-lg-4" id="video-holder" key={i}>
                <div>
                    <h3>{props.apiData[i].title}</h3>
                    <iframe className="video-frame" src={"https://www.youtube.com/embed/" + video_id} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>
            )
        }
    }
    return(
        <div className="row">
            {video_elements}
        </div>
    )
}

const SoundGallery = (props) => {
    const[sounds, setSounds] = useState([])

    useEffect(()=>{
        if(props.apiData !== undefined){
            setSounds([])
            props.apiData.forEach(data => {
                fetch("https://jsonlink.io/api/extract?url="+data.source)
                    .then(res => res.json())
                    .then(metadata => {
                        metadata.backendTitle = data.title
                        setSounds(sounds => [...sounds, metadata])
                    })
                })
        }
    }, [props])

    return(
        <div className='row'>
            {
                sounds.length === 0 ? 
                <div className="page-message col-12">Betöltés...</div>
                : sounds.map((data, idx) => 
                    <div key={idx} className="soundCard col-md-6">
                        <div>
                        <a href={data.url}><img alt={data.title} src={data.images[0] || ""}/></a>
                        <h2 className='title'><a href={data.url}>{data.backendTitle}</a></h2>
                        <p>{data.description}</p>
                        <span className="link">Hallgassa meg: <a href={data.url}>{data.domain}</a></span>
                        </div>
                    </div>
              )
            }
        </div>
    )
}

const PreziGallery = (props) => {
    let prezi_elements = []

    if(props.apiData !== undefined) {
        props.apiData.forEach((value, i)=>{
            prezi_elements.push(
                <div className="col-lg-6" id="video-holder" key={i}>
                <div>
                <iframe onload="iFrameHeight(this)" id="blockrandom-2328" name="" src={value.source} width="100%" height="500px" scrolling="auto" frameborder="1" title={value.title} class="wrapper">Nincsenek keretek</iframe>
                </div></div>
            )
        })
    }

    return (
        <div className="row">
            {prezi_elements}
        </div>
    )
}

export default MediaType
