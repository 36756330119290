import React from 'react'
import {Route, Routes } from 'react-router-dom'

import Publications from './pages/Publications/Publications'

import Home from './pages/Home/Home'
import Categories from './pages/Categories/Categories'
import Category from './pages/Category/Category'
import SubSubCategoryPage from './pages/Category/SubSubCategoryPage'

import Collections from './pages/Collections/Collections'
import Collection from './pages/Collection/Collection'
import CollectionItems from './pages/CollectionItems/CollectionItems'

import Media from './pages/Media/Media'
import MediaType from './pages/MediaType/MediaType'

import Generation from './pages/Generation/Generation'
import Lifeways from './pages/Generation/Lifeways'
import Priests from './pages/Generation/Priests'


import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer/Footer'
import PriestMemo from './pages/PriestMemo/PriestMemo'

const App = () => {
    return (
        <div>
            <Navbar />

                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/categories" element={<Categories/>}/>
                    <Route path="/category/:shortName" element={<Category/>}/>
                    <Route path="/category/publications/:id" element={<Publications/>}/>

                    <Route path="/subsub/:subcatprefix" element={<SubSubCategoryPage/>}/>

                    <Route path="/collections" element={<Collections/>}/>
                    <Route path="/collection/:shortName" element={<Collection/>}/>
                    <Route path="/collection/:shortName/:subcategoryId" element={<CollectionItems/>}/>

                    <Route path="/collection/:shortName/:subcategoryId" element={<CollectionItems/>}/>

                    <Route path="/media" element={<Media/>}/>
                    <Route path="/media/:mediaType" element={<MediaType/>}/>


                    <Route path="/generations" element={<Generation />}/>
                    <Route path="/generations/lifeways" element={<Lifeways />}/>
                    <Route path="/generations/priests" element={<Priests />}/>
                    <Route path="/generations/priest/:id" element={<PriestMemo />}/>


                </Routes>
            <Footer />
        </div>
    )
}

export default App