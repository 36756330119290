import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Title from '../../components/Title/Title'
import CategorySection from './CategorySection'
import {getAllCategories, getSubCategoriesByName} from '../../services/CategoryService'
import subcatalias from './subcatalias.json'

import './Category.scss'

const Category = () => {
    const params = useParams()
    const [mainTitle, setMainTitle] = useState(params.shortName);

    useEffect(() => {
        getAllCategories().then(
            data => {
                try {
                    let res = data.find(data => data.short_name === params.shortName).title
                    setMainTitle(res)
                } catch (error) {
                }
            });
    },[params.shortName]);

    const [subcategories, setSubcategories] = useState(<div className="page-message">Adatok betöltése...</div>)

    useEffect(() => {
        getSubCategoriesByName(params.shortName).then(
            data => {
                let subcategoriesList = []

                let subSubCategories = {}

                for(var i = 0; i <data.length;i++){
                    if(data[i].short_name.includes('-') && params.shortName === "history")
                        {
                            let splittedShort = data[i].short_name.split('-')

                            if(subSubCategories[splittedShort[0]] === undefined)
                            {
                                subSubCategories[splittedShort[0]] = []
                            }
                            subSubCategories[splittedShort[0]].push(data[i])
                        }
                    else{
                            subSubCategories[data[i].short_name] = data[i]
                        }
                }
                
                // TODO RENDEZÉS?

                // const sortObject = obj => Object.keys(obj).sort().reduce((res, key) => {
                //     res[key] = obj[key]
                //     return res
                // }, {})

                // subSubCategories = sortObject(subSubCategories)

                for(var key in subSubCategories){
                    let hasSubSubCategory = Array.isArray(subSubCategories[key])

                    if(hasSubSubCategory){
                        subcategoriesList.push(
                            <SubSubCategory key={key} data={subSubCategories[key][0]}/>
                        )
                    }else{
                        subcategoriesList.push(
                            <CategorySection key={key} data={subSubCategories[key]}/>
                        )
                    }
                }

                setSubcategories(subcategoriesList)
                if(subcategoriesList.length === 1){
                    window.location.href = "publications/"+data[0].id;
                }
            });
    },[params.shortName]);


    return (
        <div  className="content-holder">
            <Title title_text={mainTitle}/>
            <div className="container">
                <br/>
                <div className="row">
                { subcategories }
                </div>
            </div>
        </div>
    )
}

const SubSubCategory = (props) => {
    let targetUrl = "/subsub/" +props.data.short_name.split('-')[0]+"-"+props.data.short_name.split('-')[1].replace(/\d+/g, '')

    let title = props.data.short_name.split('-')[1].replace(/\d+/g, '')

    subcatalias.aliases.forEach((value)=>{
        if(value.shortname === title){
            title = value.alias
        }
    })


    return (
        <div className="col-md-6 category-section-holder">
            <div className="category-section">
                <div className="img-holder">
                    <img src={props.data.image} alt={props.data.title}/>
                </div>
                <div className="description-holder">
                    <a href={targetUrl}><h2>{title}</h2></a>
                    {/* <p>{props.data.description}</p> */}
                    <a className="more-link" href={targetUrl}>Bővebben</a>
                </div>
            </div>
        </div>
    )
}

export default Category