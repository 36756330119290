import React from "react";
import "./Hero.scss";

import hatter_1 from "../../img/hatter/hatter_1.jpg"
import hatter_2 from "../../img/hatter/hatter_2.jpg"
import hatter_3 from "../../img/hatter/hatter_3.jpg"
import hatter_4 from "../../img/hatter/hatter_4.jpg"
import hatter_5 from "../../img/hatter/hatter_5.jpg"


const Hero = () => {
  let images = [hatter_1,hatter_2,hatter_3,hatter_4,hatter_5]
  
  let rnd = Math.floor(Math.random() * images.length)

  return (
    <div id="hero" style={{ 
        backgroundImage:`url(${images[rnd]})`,
    }}
    >
        <HeroTitle/>
    </div>
  );
};

const HeroTitle = () => {
  return (
    <div>
      <div className="header-1-div">
        <h1>Byzantinohungarica</h1>
      </div>
      <h2>Görögkatolikus Örökségismereti Portál</h2>
    </div>
  );
};

export default Hero;
