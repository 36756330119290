import React from 'react'

const CategorySection = (props) => {
    let targetUrl = "/category/publications/" + props.data.id

    return (
        <div className="col-md-6 category-section-holder">
            <div className="category-section">
                <div className="img-holder">
                    <img src={props.data.image} alt={props.data.title}/>
                </div>
                <div className="description-holder">
                    <a href={targetUrl}><h2>{props.data.title}</h2></a>
                    <p>{props.data.description}</p>
                    <a className="more-link" href={targetUrl}>Bővebben</a>
                </div>
            </div>
        </div>
    )
}

export default CategorySection
