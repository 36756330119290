import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getCollectionItemsBySubcategory,
  getSubcategoryDataById,
} from "../../services/CollectionsService";
import Title from "../../components/Title/Title";

import "./CollectionItems.scss"

const CollectionItems = () => {
  const params = useParams();
  const [pageTitle, setPageTitle] = useState(
    `${params.shortName} ${params.subcategoryId}`
  );

  const [isMoreYears, setMoreYears] = useState(false)

  useEffect(() => {
    getSubcategoryDataById(params.subcategoryId).then((data) => {
      setPageTitle(data.data.title);
    });
  }, [params.subcategoryId]);

  const [apiResponse, setApiResponse] = useState(
    <div className="page-message">Adatok betöltése...</div>
  );

  useEffect(() => {
    getCollectionItemsBySubcategory(params.subcategoryId).then((data) => {

      let collectionItems = [];
      let years = [];
      for (let i = 0; i < data.length; i++) {
          years.push(data[i].year_of_publication);
      }
      years = years
        .filter((item, index) => years.indexOf(item) === index)
        .sort();

        if(years.length > 1)
        {
          setMoreYears(true)
        }
        else{
          setMoreYears(false)
        }

      for (let i = 0; i < years.length; i++) {

        let res = data.filter((data) => data.year_of_publication === years[i]);
        collectionItems.push(
          <CollectionItemsSection isMoreYears={isMoreYears} key={i} year={years[i]} items={res} subcat_id={params.subcategoryId}/>
        );
      }


      setApiResponse(collectionItems);
    });
  }, [params.subcategoryId, isMoreYears]);

  return (
    <div className="content-holder">
      <Title title_text={pageTitle} />
      <div className="container collection-container">
        <div className="row" id="card-container">
          {apiResponse}
        </div>
      </div>
    </div>
  );
};

const CollectionItemsSection = (props) => {
    let collection_items = []

    for(let i = 0; i < props.items.length; i++){
        let targetUrl = process.env.REACT_APP_API_URLS_PREFIX + props.items[i].pdf_file
        collection_items.push(
            <li key={"item"+ i}><a target="_blank" rel="noreferrer noopener" href={targetUrl}>{props.items[i].title} - {props.items[i].year_of_publication}. {props.items[i].month}</a></li>
        )
    }
  return (
    <div>
        <button
          className="year-btn"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={"#section"+props.year}
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          {props.year}
        </button>
      <div className={props.isMoreYears ? "collapse" : ""} id={"section"+props.year}>
        <div className="card card-body">
            <ul className="collection-items-list">
          {collection_items}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CollectionItems;
