import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Title from '../../components/Title/Title'

import {getPublicationsBySubcategoryId, getSubCategoryById} from '../../services/CategoryService'

import './Publications.scss'

const Publications = () => {
    const params = useParams()
    const [mainTitle, setMainTitle] = useState(params.id);

    console.log(params)

    useEffect(() => {
        getSubCategoryById(params.id).then(
            data => {
                console.log(data)
                setMainTitle(data['title'])
            }
        );
    },[params.id]);

    const [publications, setPublications] = useState(<div className="page-message">Adatok betöltése...</div>)
    const [authors, setAuthors] = useState([])

    const [apiResponse, setApiResponse] = useState([])

    const allAuthor = 'Minden szerző'

    useEffect(() => {
        getPublicationsBySubcategoryId(params.id).then(
            data => {
                setApiResponse(data)
            }
        );
    },[params.id]);

    useEffect(() => {
        let apiauthors = new Set()
        apiResponse.forEach((publication)=>{
            publication.author.split(';').forEach((value)=>{
                apiauthors.add(value.trim())
            })
        }
            )
        let finalList = Array.from(apiauthors)
        finalList.sort(Intl.Collator().compare)
        finalList.unshift(allAuthor)
        setAuthors(finalList)

    },[apiResponse]);

    const [selectedAuthor, setSelectedAuthor] = useState(allAuthor)

    useEffect(()=>{

        let publicationsList = []

        apiResponse.forEach((publication)=>{
            if(publication.author.includes(selectedAuthor) || selectedAuthor === allAuthor){
                publicationsList.push(<PublicationSection key={publication.id} data = {publication} />)
            }
        })

        setPublications(publicationsList)

    }, [selectedAuthor, apiResponse])

    return (
        <div className="content-holder">
            <Title  title_text={mainTitle}/>
            <div className="container">
                <br/>
                <div className="publicationAuthorSelectHolder">
                    <select onChange={(event)=>{
                        setSelectedAuthor(event.target.value)
                    }}>
                    {authors.map((x)=><option key={x} value={x}>{x}</option>)}
                </select>
                </div>
                <div className="row">
                    { publications }
                </div>
            </div>
        </div>
    )
}

const PublicationSection = (props) => {

    let link_target = "";
    if(props.data.pdf_file !== null){
        link_target = process.env.REACT_APP_API_URLS_PREFIX + props.data.pdf_file
    }else{
        link_target = props.data.url
    }

    return (
        <div className="col-md-12 publication-section-holder">
        <div className="publication-section">
                <div className="author">{props.data.author.replaceAll(';', ',')}</div>
            <a href={link_target}>
                <h2>{props.data.title}</h2>
            </a>
                <p>{props.data.description}</p>
            <a className="more-link" target="_blank" rel="noreferrer noopener" href={link_target}>Bővebben &#8594;</a>
        </div>
        </div>
    )
}

export default Publications
