import React from 'react'
import './Media.scss'
import Title from '../../components/Title/Title'
import CategoryCard from '../../components/Cards/CategoryCard'
import description from './mediadescription.json'

const Media = () => {
    return (
        <div id="media" className="content-holder">
            <Title title_text = "Médiatár" />
            <div className="container">
                <div className="row" id="card-container">
                    <div className="col-lg-3 col-md-6">
                    <CategoryCard
                    title="Képgalériák" 
                    description={description.image}
                    target="media/image"
                    icon={"media/media/images/icons/camera-solid.svg"}
                    />
                    </div>
                    <div className="col-lg-3 col-md-6">
                    <CategoryCard
                    title="Filmtár" 
                    description={description.movie}
                    target="media/youtube"
                    icon={"media/media/images/icons/youtube.svg"}
                    />
                    </div>
                    <div className="col-lg-3 col-md-6">
                    <CategoryCard
                    title="Hangtár" 
                    description={description.sounds}
                    target="media/sound"
                    icon={"media/media/images/icons/music-solid.svg"}
                    />
                    </div>

                    <div className="col-lg-3 col-md-6">
                    <CategoryCard
                    title="Prezentációk" 
                    description={description.presentation}
                    target="media/prezi"
                    icon={"media/media/images/icons/person-chalkboard-solid.svg"}
                    />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Media
