import React from 'react'
import './CategoryCard.scss'

const CategoryCard = (props) => {
    return (
        <div className="category-card">
            <a href={props.target}>
            <div className="icon">
                <img src={props.icon} alt="icon"/>
            </div>
            </a>
            <a className="card-title" href={props.target}>{props.title}</a>
            <p>{props.description}</p>
            <a className="button" href={props.target}>Bővebben</a>
        </div>
    )
}

CategoryCard.defaultProps = {
    title: "Sample Text",
    description: " Aliquam in velit quis purus euismod blandit. Donec lacinia tincidunt ligula ac molestie.",
    target: "../../../public/dummy/sample.svg"
}


export default CategoryCard
