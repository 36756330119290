import React, {useState, useEffect} from 'react'
import './Categories.scss'
import Title from '../../components/Title/Title'
import CategoryCard from '../../components/Cards/CategoryCard'
import {getAllCategories} from '../../services/CategoryService'

const Categories = () => {

    const [apiResponse, setApiResponse] = useState(<div className="page-message">Adatok betöltése...</div>);

    useEffect(() => {
        getAllCategories().then(
            data => {
                let components = [];
                for(var i = 0;i<data.length;i++) {
                    components.push(
                    <div key={i} className="col-lg-4 col-md-6">
                        <CategoryCard 
                            title={data[i].title} 
                            description={data[i].description}
                            icon={data[i].icon}
                            target={'/category/' + data[i].short_name}
                        />
                    </div>);
                }
                
                setApiResponse(components)});
    },[]);

    return (
        <div id="categories" className="content-holder">
            <Title title_text = "Kategóriák/Topics" />
            <div className="container">
                <div className="row" id="card-container">
                    {apiResponse}
                </div>
            </div>
        </div>
    )
}

export default Categories