const axios = require('axios');

export async function getLifeways() {
    try{
        const response = await axios.get(process.env.REACT_APP_API_URLS_PREFIX + 'api/lifeway/');
        return response.data;
    }catch(error) {
        return [];
    }
}

export async function getPriestsShort() {
    try{
        const url = process.env.REACT_APP_API_URLS_PREFIX +'/api/priests_short/'

        const response = await axios.get(url);
        return response.data;
    }catch(error) {
        return [];
    }
}

export async function getPriestById(id) {
    try{
        const url = process.env.REACT_APP_API_URLS_PREFIX +'/api/priest/'+id

        const response = await axios.get(url);
        return response.data;
    }catch(error) {
        return [];
    }
}