import React from 'react'
import './Title.scss'

const Title = (props) => {
    return (
        <div className="title-holder">
            <div className="flower">&#10058;</div>
            <h1>{props.title_text}</h1>
        </div>
    )
}

Title.defaultProps = {
    title_text: "Sample Text"
}

export default Title
