import React from 'react'
import Title from '../../components/Title/Title'
import CategoryCard from '../../components/Cards/CategoryCard'

const Generation = () => {
    let lifeways_icon = process.env.REACT_APP_API_URLS_PREFIX + "media/media/images/icons/generations/lifeway_icon.svg"
    let priests_icon = process.env.REACT_APP_API_URLS_PREFIX + "media/media/images/icons/generations/priest_icon.svg"

    return (
        <div id="lifeway" className="content-holder">
            <Title title_text = "Nemzedékről nemzedékre" />
             <div className="container">
                <div className="row" id="accordion">

                    <div className="col-md-6">
                        <CategoryCard
                            target="generations/lifeways"
                            icon={lifeways_icon}
                            title="Görögkatolikus életutak"
                            description=""
                        />
                    </div>

                    <div className="col-md-6">
                        <CategoryCard
                            target="generations/priests"
                            icon={priests_icon}
                            title="Papi emlékoldalak"
                            description=""
                        />
                    </div>


                </div>
             </div>
        </div>
    )
}

export default Generation
